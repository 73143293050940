import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Image } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { isPhone } from "../constants/env";
import { Footer } from "../components/pages/homepage/footer/Footer";
import { Fragment } from "react";

export default function Page404() {
  const { i18n } = useTranslation("common");
  return (
    <Fragment>
      <div
        style={{ height: "calc(100vh - 50px)", padding: 32 }}
        className="flex flex-col justify-center items-center gap-10"
      >
        <Image
          width="50vw"
          height="50vh"
          radius="xl"
          src="/img/404.svg"
          alt="Not found"
        />
        <h1 style={{ fontSize: 48, marginTop: 32 }}>{i18n.t("page404")}</h1>
      </div>
      {!isPhone && <Footer isVisible />}
    </Fragment>
  );
}

// @ts-ignore
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      // Will be passed to the page component as props
    },
  };
}
